<template>
  <v-container fluid>
    <section class="m-inner-section ma-4">
      <div class="m-inner-page">
        <v-row class="my-0" align="center">
          <v-col md="6">
            <h6 class="text-h6 gray--text text--darken-3">Müqavilə</h6>
          </v-col>
          <v-col md="6">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                depressed
                @click="addNewContract()"
                v-if="page === 'edit'"
              >
                <v-icon class="mr-2" medium> mdi-plus </v-icon>
                <span> Əlavə et </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

      <List
        :totalCount="totalCount"
        :items="items"
        :isFetchingItems="isFetchingItems"
        @editItem="handleEditItem"
        @deleteItem="handleDeleteItem"
      />

      <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="1000px"
        v-if="page === 'edit'"
      >
        <v-card>
          <div class="py-5 px-4 border-bottom">
            <span
              class="text-h6 gray--text text--darken-3 font-weight-regular"
              v-text="
                selectedContract ? 'Müqaviləin redaktəsi' : 'Yeni müqavilə'
              "
            >
            </span>
          </div>
          <div class="px-4 pt-4 pb-1">
            <v-form
              id="studentContractForm"
              ref="studentContractForm"
              @submit.prevent="validateForm"
              v-model="studentContractFormValid"
              lazy-validation
            >
              <v-row class="my-0">
                <v-col cols="12" class="mb-3">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="contractDateFormatted"
                        label="Tarixi*"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        filled
                        dense
                        :rules="rules.contractDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="contractDate"
                      @input="checkDateInput"
                      :month-format="getMonthFormat"
                      :header-date-format="getHeaderDateFormat"
                      :weekday-format="getWeekdayFormat"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="mb-3">
                  <v-select
                    label="Növü*"
                    filled
                    dense
                    hide-details
                    item-text="value"
                    item-value="id"
                    :items="contractTypes"
                    v-model="form.studentContractTypeId"
                    :rules="rules.contractType"
                  />
                </v-col>
                <v-col cols="6" class="mb-3">
                  <v-text-field
                    hide-details
                    filled
                    dense
                    label="Nömrəsi*"
                    v-model="form.number"
                    :rules="rules.number"
                  />
                </v-col>
                <v-col cols="6" class="mb-3">
                  <v-text-field
                    type="text"
                    label="Ümumi qiyməti"
                    filled
                    dense
                    v-model="form.oldPrice"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="mb-3">
                  <v-text-field
                    type="number"
                    label="Endirim Faizi"
                    filled
                    dense
                    value="0"
                    v-model="form.discountPercent"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" class="mb-3">
                  <v-text-field
                    type="number"
                    label="Endirimli qiymət"
                    filled
                    dense
                    disabled
                    v-model="form.totalPrice"
                  ></v-text-field>
                </v-col>               
              </v-row>
            </v-form>
          </div>
          <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
            <v-btn color="gray lighten-3" depressed @click="closeDialog">
              Bağla
            </v-btn>
            <v-btn
              depressed
              color="success"
              form="studentContractForm"
              type="submit"
              :loading="loading"
            >
              {{ selectedContract ? "Yadda saxla" : "Yarat" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ConfirmationPopup
        id="delete-contract-modal"
        title="Müqavilənin silinməsi"
        v-if="deletedContract"
        :dialog="confirmationDialog"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
        :confirmText="'Sil'"
      >
        Müqaviləni silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </section>
  </v-container>
</template>

<script>
import List from "./components/List/index.vue";
import API from "@/services";
import datePickerFormatterMixin from "@/mixins/datePickerFormatter";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../../../../event-bus";

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  mixins: [datePickerFormatterMixin],
  data() {
    return {
      form: {
        startDate: null,
        endDate: null,
        studentContractTypeId: null,
        number: null,
        oldPrice:null,
        discountPercent:0,
        totalPrice:null
        
      },
      rules: {
        contractDate: [(v) => !!v || "Tarix tələb olunur"],
        contractType: [(v) => !!v || "Növ tələb olunur"],
        number: [(v) => !!v || "Nömrə tələb olunur"],
        workbookNumber: [
          (v) => !!v || "Əmək kitabçasının nömrəsi tələb olunur",
        ],
        isPermanent: [(v) => !!v || "Müddəti tələb olunur"],
      },
      items: [],
      isFetchingItems: false,
      totalCount: 0,
      dialog: false,
      studentContractFormValid: false,
      loading: false,
      contractDate: [],
      dateMenu: false,
      contractTypes: [],      
      selectedContract: null,
      deletedContract: null,
      confirmationDialog: false,
    };
  },
  components: {
    List,
    ConfirmationPopup,
  },
  computed: {
    contractDateFormatted: {
      get() {
        return this.formatRangeDate(this.contractDate);
      },
      set(value) {
        this.value = value;
      },
    },
  },
  watch: {
   "form.oldPrice": {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.getTotalPrice()
        }
      },
    },
     "form.discountPercent": {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.getTotalPrice()
        }
      },
    },
    contractDate: {
      handler(val) {
        this.form.startDate = val[0];
        this.form.endDate = val[1];
      },
    },
  },
  methods: {
    getTotalPrice(){
      this.form.totalPrice=(100-this.form.discountPercent)*this.form.oldPrice/100;
    },
    resetForm() {
      this.form = {
        startDate: null,
        endDate: null,
        studentContractTypeId: null,
        number: null,
        oldPrice:null,
        discountPercent:0,
        totalPrice:null
      };
      this.contractDate = [];
      this.$refs.studentContractForm.reset();
      this.dialog = false;
    },
    checkDateInput() {
      if (this.contractDate.length == 2) {
        this.dateMenu = false;
      }
    },
    formatRangeDate(date) {
      if (date[1]) {
        this.form.startDate = date[0];
        this.form.endDate = date[1];
        return (
          this.formatDate(date[0], "string") +
          " ~ " +
          this.formatDate(date[1], "string")
        );
      } else if (date[0]) {
        this.form.startDate = date[0];
        return this.formatDate(date[0], "string");
      } else {
        return "";
      }
    },
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.fetchStudentContracts(this.$route.params.id);
      }
    },
    fetchStudentContracts(id) {
      this.isFetchingItems = true;
      API.fetchStudentContracts(id)
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Tələbənin müqavilələrini əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    validateForm() {
      const isValid = this.$refs.studentContractForm.validate();

      if (this.studentContractFormValid && isValid) {
        if (this.isPermanent === "true") {
          this.form.isPermanent = true;
        } else {
          this.form.isPermanent = false;
        }

        this.selectedContract
          ? this.handleEditContract(this.form)
          : this.handleAddContract(this.form);
      }
    },
    async fetchStudentContractTypes() {
      this.contractTypes = await API.fetchStudentContractTypes();
    },
    addNewContract() {
      this.selectedContract = null;
      this.dialog = true;
    },
    handleEditItem(item) {
      this.loading = true;

      API.getStudentContract(item.studentId, item.id)
        .then((response) => {
          this.selectedContract = JSON.parse(JSON.stringify(response));
          this.form = { ...response };
          this.contractDate = [response.startDate, response.endDate];

          if (this.form.isPermanent == true) {
            this.isPermanent = "true";
          } else {
            this.isPermanent = "false";
          }
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Tələbənin müqaviləsini əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });

      this.dialog = true;
    },
    handleDeleteItem(item) {
      this.deletedContract = { ...item };
      this.confirmationDialog = true;
    },
    closeDialog() {
      this.selectedContract = null;
      this.contractDate = [];
      this.resetForm();
      this.dialog = false;
    },
    handleAddContract(formData) {
      this.insertStudentContract(this.$route.params.id, formData);
    },
    handleEditContract(formData) {
      this.updateStudentContract(
        this.selectedContract.studentId,
        this.selectedContract.id,
        formData
      );
    },
    handleDeleteConfirmation() {
      API.deleteStudentContract(
        this.deletedContract.studentId,
        this.deletedContract.id
      )
        .then(() => {
          this.fetchStudentContracts(this.deletedContract.studentId);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Müqavilə silindi.",
          });
          this.handleDeleteCancellation();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Müqaviləni silmək mümkün olmadı.",
          });
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.deletedContract = null;
    },
    insertStudentContract(id, data) {
      this.loading = true;
      API.insertStudentContract(id, data)
        .then(() => {
          this.fetchStudentContracts(this.$route.params.id);
          this.closeDialog();
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Müqavilə əlavə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Müqavilə əlavə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateStudentContract(studentId, id, data) {
      this.loading = true;
      API.updateStudentContract(studentId, id, data)
        .then(() => {
          this.fetchStudentContracts(this.$route.params.id);
          this.closeDialog();
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Müqavilə redaktə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Müqaviləni redaktə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.checkID();
    this.fetchStudentContractTypes();
  },
};
</script>
<style></style>
