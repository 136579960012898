<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :server-items-length="totalCount"
    :hide-default-footer="true"
  >    

    <template v-slot:[`item.startDate`]="{ item }">
      <span v-text="item.startDate ? formatDate(item.startDate, 'string') : ''"></span>
    </template>

    <template v-slot:[`item.endDate`]="{ item }">
      <span v-text="item.endDate ? formatDate(item.endDate, 'string') : ''"></span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-end">
        <v-btn icon small @click="$emit('editItem', item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon small @click="$emit('deleteItem', item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import datePickerFormatterMixin from '@/mixins/datePickerFormatter'
export default {
  mixins: [datePickerFormatterMixin],
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    isFetchingItems: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'Bağlanma tarixi', value: 'startDate', sortable: false },
        { text: 'Bitmə tarixi', value: 'endDate', sortable: false },
        { text: 'Növü', value: 'studentContractType', sortable: false },
        { text: 'Nömrəsi', value: 'number', sortable: false },
        {
          text: 'Ümumi qiymət',
          value: 'oldPrice',
          sortable: false
        },
        { text: 'Endirim faizi', value: 'discountPercent', sortable: false },
         {
          text: 'Endirimli qiymət',
          value: 'totalPrice',
          sortable: false
        },
        { text: '', value: 'actions', sortable: false }
      ],
      options: {}
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      let stringDate = new Date(date)
      let formattedDate =
        stringDate.getFullYear() + '-' + (stringDate.getMonth() + 1) + '-' + stringDate.getDate()

      const [year, month, day] = formattedDate.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>
